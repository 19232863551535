import dayAPI from '~/utils/dayAPI';
/** 預設選擇權起始時間 開倉日 */
export const getOptionContractOpenDate = () => {
    const date = dayAPI();
    const lastWednesdayDate = date
        .subtract(1, 'week')
        .day(3)
        .startOf('day')
        .add(15, 'hour')
        .add(0, 'minute');
    const thisWednesdayDate = date
        .subtract(0, 'week')
        .day(3)
        .startOf('day')
        .add(15, 'hour')
        .add(0, 'minute');
    //判斷是否已結算 換下個合約時間
    const isSettlement = 
    /** 結算完後下一個交易日 */
    dayAPI().startOf('day').day() > 3 ||
        /** 結算日15:00後 */
        (dayAPI().startOf('day').day() === 3 && dayAPI().hour() >= 15);
    const startDateTime = isSettlement ? thisWednesdayDate : lastWednesdayDate;
    return startDateTime;
};
/** 選擇權開盤時間 最近一次開盤時間 */
export const getOptionIntradayStartDate = (intraday) => {
    const intraDate = dayAPI(intraday).startOf('day');
    const closedDateTime = intraDate.add(15, 'hour');
    const currentDateTime = dayAPI();
    /** 當下15:00 >= intraday的15:00 給[intraday 15:00] 否則 [intraday 08:45] */
    const result = currentDateTime >= closedDateTime
        ? intraDate.add(15, 'hour')
        : intraDate.add(8, 'hour').add(45, 'minute');
    return result;
};
/** 預設選擇權終止時間 最近一次收盤時間 */
export const getOptionIntradayEndDate = (intraday) => {
    const intraDate = dayAPI(intraday).startOf('day');
    const closedDateTime = intraDate.add(15, 'hour');
    const currentDateTime = dayAPI();
    const result = currentDateTime >= closedDateTime
        ? intraDate.add(1, 'day').add(5, 'hour')
        : intraDate.add(13, 'hour').add(45, 'minute');
    return result;
};
/** 近期10日交易日選擇權起始時間 */
export const getOptionStartTradeTime = (days, lastDate) => {
    /** 模擬開倉日 */
    const contractOpenDateTime = { date: getOptionContractOpenDate() };
    /** 當天的日期08:45 || 13:45 */
    const intradayOpenDateTime = lastDate.map(s => ({ date: dayAPI(s) }));
    /** 近期N天有交易的日期 08:45 && 13:45 */
    const openDateTime = days.map(s => ({
        date1: dayAPI(s).startOf('day').add(8, 'hour').add(45, 'minute'),
        date2: dayAPI(s).startOf('day').add(15, 'hour').add(0, 'minute'),
    }));
    const dateTime = openDateTime
        .flatMap(obj => [obj.date2, obj.date1])
        .map(dateStr => ({ date: dateStr }));
    const merge = intradayOpenDateTime
        .concat(contractOpenDateTime)
        .concat(dateTime)
        .sort((a, b) => b.date.unix() - a.date.unix());
    const result = [...new Set(merge.map(s => s.date.format('YYYY/MM/DD HH:mm')))];
    return result;
};
/** 近期10日交易日選擇權結束時間 */
export const getOptionEndTradeTime = (days, lastDate) => {
    const intradayCloseDateTime = lastDate.map(s => ({
        date: dayAPI(s),
    }));
    const closeDateTime = days.map(s => ({
        date1: dayAPI(s).startOf('day').add(13, 'hour').add(45, 'minute'),
        date2: dayAPI(s).startOf('day').add(1, 'day').add(5, 'hour').add(0, 'minute'),
    }));
    const dateTime = closeDateTime
        .flatMap(obj => [obj.date2, obj.date1])
        .map(dateStr => ({ date: dateStr }));
    const merge = intradayCloseDateTime.concat(dateTime);
    const result = [...new Set(merge.map(s => s.date.format('YYYY/MM/DD HH:mm')))];
    return result.map(s => dayAPI(s).clone());
};
