import { proxy } from 'valtio';
import dayAPI from '~/utils/dayAPI';
import { getOptionContractOpenDate } from './getOptionContractDateTime';
export const optionAnalyzeStore = proxy({
    /** 目前合約 */
    currentContract: '',
    /** 所有合約 */
    allContract: [''],
    /** 所有合約結算日 */
    allContractSettlementDate: { ['']: '' },
    /** 該合約結算日 */
    currentSettlementDate: '',
    /** 預設選擇權區間開始時間 */
    contractStartDateTime: getOptionContractOpenDate(),
    /** 預設選擇權區間結束時間 */
    intradayEndTime: dayAPI(),
    /** 口數篩選 */
    filterLot: 0,
    /** 金額篩選 */
    filterAmount: 0,
    //useOptionQuote傳遞
    /** 價平 */
    atTheMoneyPrice: 0,
    /** 價平和 */
    atTheMoneySum: 0,
    /** 剩餘天數 */
    remainingDays: 0,
    /** 合成期貨 */
    syntheticFutures: 0,
    //客製化功能(待開發)
    /** 細部時間模式 */
    detailTimeType: false,
    /** 價格回朔模式 */
    backtrackPriceTime: false,
});
//contract
