import camelcaseKeys from 'camelcase-keys';
import useSWR from 'swr-0-5-6';
import { apirc } from '~/configs/apirc';
export const useOpbsResource = (params) => {
    let url = '';
    if (params?.contractMonth) {
        if (params?.from) {
            url = apirc.options.opbsUrlGet({
                contract_month: params?.contractMonth,
                from: params?.from,
                to: params?.to,
                q_gte: params?.q_gte,
                q_lte: params?.q_lte,
                m_gte: params?.m_gte,
                m_lte: params?.m_lte,
            });
        }
    }
    const swr = useSWR(url || null, {
        refreshInterval: 10000,
    });
    return {
        res: {
            ...swr,
            data: swr.data ? camelcaseKeys(swr.data) : null,
        },
    };
};
